import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageComponent = makeShortcode("ImageComponent");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`ImageComponent`}</h2>
    <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice." mdxType="ImageComponent">
      <img {...{
        "src": "../../images/blossom.jpg",
        "alt": "blossom"
      }}></img>
    </ImageComponent>
    <Row mdxType="Row">
  <Column colLg={4} mdxType="Column">
        <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice." mdxType="ImageComponent">
          <img {...{
            "src": "../../images/blossom.jpg",
            "alt": "blossom"
          }}></img>
        </ImageComponent>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colLg={6} mdxType="Column">
        <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice." mdxType="ImageComponent">
          <img {...{
            "src": "../../images/blossom.jpg",
            "alt": "blossom"
          }}></img>
        </ImageComponent>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colLg={8} mdxType="Column">
        <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice." mdxType="ImageComponent">
          <img {...{
            "src": "../../images/blossom.jpg",
            "alt": "blossom"
          }}></img>
        </ImageComponent>
  </Column>
    </Row>
    <h3>{`Zoom`}</h3>
    <p>{`Zoom styles are in progress/TBD`}</p>
    <ImageComponent zoom caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice." mdxType="ImageComponent">
      <img {...{
        "src": "../../images/blossom.jpg",
        "alt": "blossom"
      }}></img>
    </ImageComponent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      